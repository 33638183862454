export const matches = [
    {
        sport_title: "MLS",
        home_team: "LA Galaxy",
        away_team: "New York Red Bulls"
    },
    {
        sport_title: "MLS",
        home_team: "Atlanta United",
        away_team: "Seattle Sounders"
    },
    {
        sport_title: "MLS",
        home_team: "FC Dallas",
        away_team: "Sporting Kansas City"
    },
    {
        sport_title: "MLS",
        home_team: "New England Revolution",
        away_team: "LAFC"
    },
    {
        sport_title: "MLS",
        home_team: "Colorado Rapids",
        away_team: "Philadelphia Union"
    },
    {
        sport_title: "MLS",
        home_team: "Real Salt Lake",
        away_team: "Portland Timbers"
    },
    {
        sport_title: "MLS",
        home_team: "Houston Dynamo",
        away_team: "Chicago Fire"
    },
    {
        sport_title: "MLS",
        home_team: "Columbus Crew",
        away_team: "Nashville SC"
    },
    {
        sport_title: "NWSL",
        home_team: "Portland Thorns FC",
        away_team: "North Carolina Courage"
    },
    {
        sport_title: "NWSL",
        home_team: "Orlando Pride",
        away_team: "Utah Royals FC"
    },
    {
        sport_title: "NWSL",
        home_team: "Gotham FC",
        away_team: "Racing Louisville FC"
    },
    {
        sport_title: "NWSL",
        home_team: "Kansas City NWSL",
        away_team: "San Diego Wave FC"
    },
    {
        sport_title: "NWSL",
        home_team: "Angel City FC",
        away_team: "Houston Dash"
    },
    {
        sport_title: "NWSL",
        home_team: "NC Courage",
        away_team: "Orlando Pride"
    },
    {
        sport_title: "NFL",
        home_team: "Los Angeles Rams",
        away_team: "New England Patriots"
    },
    {
        sport_title: "NFL",
        home_team: "San Francisco 49ers",
        away_team: "Dallas Cowboys"
    },
    {
        sport_title: "NFL",
        home_team: "Seattle Seahawks",
        away_team: "Pittsburgh Steelers"
    },
    {
        sport_title: "NFL",
        home_team: "Baltimore Ravens",
        away_team: "New Orleans Saints"
    },
    {
        sport_title: "NFL",
        home_team: "Cleveland Browns",
        away_team: "Indianapolis Colts"
    },
    {
        sport_title: "NFL",
        home_team: "Arizona Cardinals",
        away_team: "Tennessee Titans"
    },
    {
        sport_title: "NFL",
        home_team: "Miami Dolphins",
        away_team: "Philadelphia Eagles"
    },
    {
        sport_title: "NFL",
        home_team: "Las Vegas Raiders",
        away_team: "Minnesota Vikings"
    },
    {
        sport_title: "NBA",
        home_team: "Boston Celtics",
        away_team: "Miami Heat"
    },
    {
        sport_title: "NBA",
        home_team: "Chicago Bulls",
        away_team: "Los Angeles Clippers"
    },
    {
        sport_title: "NBA",
        home_team: "Houston Rockets",
        away_team: "Philadelphia 76ers"
    },
    {
        sport_title: "NBA",
        home_team: "Dallas Mavericks",
        away_team: "Atlanta Hawks"
    },
    {
        sport_title: "NBA",
        home_team: "New York Knicks",
        away_team: "Phoenix Suns"
    },
    {
        sport_title: "NBA",
        home_team: "Denver Nuggets",
        away_team: "Utah Jazz"
    },
    {
        sport_title: "NBA",
        home_team: "Portland Trail Blazers",
        away_team: "Toronto Raptors"
    },
    {
        sport_title: "NBA",
        home_team: "Memphis Grizzlies",
        away_team: "New Orleans Pelicans"
    },
    {
        sport_title: "MLB",
        home_team: "San Francisco Giants",
        away_team: "Houston Astros"
    },
    {
        sport_title: "MLB",
        home_team: "Atlanta Braves",
        away_team: "New York Mets"
    },
    {
        sport_title: "MLB",
        home_team: "St. Louis Cardinals",
        away_team: "Philadelphia Phillies"
    },
    {
        sport_title: "MLB",
        home_team: "Oakland Athletics",
        away_team: "Tampa Bay Rays"
    },
    {
        sport_title: "MLB",
        home_team: "Milwaukee Brewers",
        away_team: "Cleveland Guardians"
    },
    {
        sport_title: "MLB",
        home_team: "Chicago White Sox",
        away_team: "Washington Nationals"
    },
    {
        sport_title: "MLB",
        home_team: "Minnesota Twins",
        away_team: "Cincinnati Reds"
    },
    {
        sport_title: "MLB",
        home_team: "Toronto Blue Jays",
        away_team: "Seattle Mariners"
    },
    {
        sport_title: "NHL",
        home_team: "Toronto Maple Leafs",
        away_team: "Boston Bruins"
    },
    {
        sport_title: "NHL",
        home_team: "New York Rangers",
        away_team: "Washington Capitals"
    },
    {
        sport_title: "NHL",
        home_team: "Edmonton Oilers",
        away_team: "Montreal Canadiens"
    },
    {
        sport_title: "NHL",
        home_team: "Calgary Flames",
        away_team: "Philadelphia Flyers"
    },
    {
        sport_title: "NHL",
        home_team: "Dallas Stars",
        away_team: "St. Louis Blues"
    },
    {
        sport_title: "NHL",
        home_team: "Vancouver Canucks",
        away_team: "Nashville Predators"
    },
    {
        sport_title: "NHL",
        home_team: "Carolina Hurricanes",
        away_team: "Chicago Blackhawks"
    },
    {
        sport_title: "NHL",
        home_team: "Los Angeles Kings",
        away_team: "Florida Panthers"
    },
    {
        sport_title: "MLR",
        home_team: "Austin Gilgronis",
        away_team: "Rugby United New York"
    },
    {
        sport_title: "MLR",
        home_team: "Seattle Seawolves",
        away_team: "Houston SaberCats"
    },
    {
        sport_title: "MLR",
        home_team: "Rugby ATL",
        away_team: "Old Glory DC"
    },
    {
        sport_title: "MLR",
        home_team: "New England Free Jacks",
        away_team: "San Diego Legion"
    },
    {
        sport_title: "MLR",
        home_team: "Toronto Arrows",
        away_team: "LA Giltinis"
    },
    {
        sport_title: "MLR",
        home_team: "NOLA Gold",
        away_team: "Austin Gilgronis"
    },
    {
        sport_title: "MLR",
        home_team: "Utah Warriors",
        away_team: "Rugby ATL"
    },
    {
        sport_title: "MLR",
        home_team: "Houston SaberCats",
        away_team: "Old Glory DC"
    },
    {
        sport_title: "MLU",
        home_team: "Seattle Cascades",
        away_team: "San Francisco FlameThrowers"
    },
    {
        sport_title: "MLU",
        home_team: "Toronto Rush",
        away_team: "Los Angeles Aviators"
    },
    {
        sport_title: "MLU",
        home_team: "Vancouver Riptide",
        away_team: "Montreal Royal"
    },
    {
        sport_title: "MLU",
        home_team: "Chicago Wildfire",
        away_team: "Atlanta Hustle"
    },
    {
        sport_title: "MLU",
        home_team: "Austin Sol",
        away_team: "Philadelphia Phoenix"
    },
    {
        sport_title: "MLU",
        home_team: "New York Empire",
        away_team: "DC Breeze"
    },
    {
        sport_title: "MLU",
        home_team: "Boston Whitecaps",
        away_team: "San Francisco FlameThrowers"
    },
    {
        sport_title: "MLU",
        home_team: "Los Angeles Aviators",
        away_team: "Seattle Cascades"
    },
    {
        sport_title: "MLV",
        home_team: "Dallas Aces",
        away_team: "Boston Bombers"
    },
    {
        sport_title: "MLV",
        home_team: "Atlanta Smash",
        away_team: "Philadelphia Phantoms"
    },
    {
        sport_title: "MLV",
        home_team: "Seattle Stormers",
        away_team: "Miami Waves"
    },
    {
        sport_title: "MLV",
        home_team: "Houston Hurricanes",
        away_team: "Orlando Thunder"
    },
    {
        sport_title: "MLV",
        home_team: "Denver Blizzards",
        away_team: "Washington Generals"
    },
    {
        sport_title: "MLV",
        home_team: "Las Vegas Knights",
        away_team: "New Orleans Jesters"
    },
    {
        sport_title: "MLV",
        home_team: "Phoenix Sunsations",
        away_team: "Minneapolis Miners"
    },
    {
        sport_title: "MLV",
        home_team: "Portland Pioneers",
        away_team: "Nashville Strikers"
    }

]