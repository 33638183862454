export const navLinks = [
    {
        id: 1,
        link: 'BasketBall',
        cName: 'navLink2',
    },
    {
        id: 2,
        link: 'FootBall',
        cName: 'navLink2',
    },
    {
        id: 3,
        link: 'BaseBall',
        cName: 'navLink2',
    },
    {
        id: 4,
        link: 'Golf',
        cName: 'navLink2',
    },
    {
        id: 5,
        link: 'Tennis',
        cName: 'navLink2',
    },
    {
        id: 6,
        link: 'Rugby',
        cName: 'navLink2',
    },
    {
        id: 7,
        link: 'VoleyBall',
        cName: 'navLink2',
    },
    {
        id: 8,
        link: 'Boxing',
        cName: 'navLink2',
    },
    {
        id: 9,
        link: 'Cricket',
        cName: 'navLink2',
    },
    {
        id: 10,
        link: 'Hockey',
        cName: 'navLink2',
    },
    {
        id: 11,
        link: 'Badminton',
        cName: 'navLink2',
    },
    {
        id: 12,
        link: 'Soccer',
        cName: 'navLink2',
    },
    {
        id: 13,
        link: 'Swimming',
        cName: 'navLink2',
    },
    {
        id: 14,
        link: 'TableTennis',
        cName: 'navLink2',
    },
    {
        id: 15,
        link: 'Athletics',
        cName: 'navLink2',
    },
    {
        id: 16,
        link: 'Cycling',
        cName: 'navLink2',
    },
    {
        id: 17,
        link: 'Skiing',
        cName: 'navLink2',
    },
    {
        id: 18,
        link: 'Wrestling',
        cName: 'navLink2',
    },
    {
        id: 19,
        link: 'Surfing',
        cName: 'navLink2',
    },
    {
        id: 20,
        link: 'Gymnastics',
        cName: 'navLink2',
    },
    {
        id: 21,
        link: 'Handball',
        cName: 'navLink2',
    },
    {
        id: 22,
        link: 'Fencing',
        cName: 'navLink2',
    },
    {
        id: 23,
        link: 'Rowing',
        cName: 'navLink2',
    },
    {
        id: 24,
        link: 'Volleyball',
        cName: 'navLink2',
    },
    {
        id: 25,
        link: 'Boxing',
        cName: 'navLink2',
    },
    {
        id: 26,
        link: 'Skating',
        cName: 'navLink2',
    },
    {
        id: 27,
        link: 'Shooting',
        cName: 'navLink2',
    },
    {
        id: 28,
        link: 'Archery',
        cName: 'navLink2',
    },
    {
        id: 29,
        link: 'Weightlifting',
        cName: 'navLink2',
    },
    {
        id: 30,
        link: 'Curling',
        cName: 'navLink2',
    },
];
