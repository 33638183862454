import male1 from '../../assets/images/male1.jpg'
import male2 from '../../assets/images/male2.jpg'
import male3 from '../../assets/images/male3.jpg'
import male4 from '../../assets/images/male4.jpg'
import male5 from '../../assets/images/male5.jpg'
import female1 from '../../assets/images/female1.jpg'
import female2 from '../../assets/images/female2.jpg'
import female3 from '../../assets/images/female3.jpg'
import female4 from '../../assets/images/female4.jpg'
import female5 from '../../assets/images/female5.jpg'

const reviews = [
    {
        id: "1",
        name: "Alex Johnson",
        text: "Amazing sports betting experience! The interface is user-friendly and the odds are competitive. Definitely my go-to site for placing bets.",
        avatar: male1
    },
    {
        id: "2",
        name: "Sarah Thompson",
        text: "I love the variety of sports available for betting. From football to basketball and even esports, this site has it all. Quick payouts too!",
        avatar: female1

    },
    {
        id: "3",
        name: "Daniel Martinez",
        text: "Efficient customer support and smooth navigation make this site stand out. Placing bets is a breeze and the live betting feature adds extra excitement.",
        avatar: male2

    },
    {
        id: "4",
        name: "Sophia Williams",
        text: "Highly recommended for both beginners and experienced bettors. The welcome bonus was generous, and I've been enjoying consistent wins.",
        avatar: female2

    },
    {
        id: "5",
        name: "James Brown",
        text: "The in-play betting is phenomenal. Being able to place bets while the game is ongoing adds a whole new level of fun and engagement.",
        avatar: male3

    },
    {
        id: "6",
        name: "Emily Davis",
        text: "I appreciate the transparency in odds and results. The site keeps you informed with real-time updates, and their mobile app is incredibly convenient.",
        avatar: female3

    },
    {
        id: 7,
        name: "David Taylor",
        text: "This site offers a wide range of payment options, which makes depositing and withdrawing hassle-free. Plus, their promotions are quite attractive.",
        avatar: male4

    },
    {
        id: 8,
        name: "Olivia Anderson",
        text: "The site's layout is intuitive, and I've never experienced any glitches. The live streaming feature is a huge plus for following matches in real-time.",
        avatar: female4
    },
    {
        id: 9,
        name: "Ethan Rodriguez",
        text: "I've been using this site for years, and it has never disappointed. The responsive customer support team and frequent bonuses keep me coming back.",
        avatar: male5
    },
    {
        id: 10,
        name: "Ava Harris",
        text: "The mobile version is just as smooth as the desktop site. Betting on the go has never been easier, and the odds are always competitive.",
        avatar: female5
    }
];

export default reviews;
