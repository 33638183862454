import React, { useEffect, useState } from 'react'
import './styles.css'
import Cookies from 'universal-cookie'
import moment from 'moment/moment';
import { getHistories } from '../../../../api';
import { Badge, Col, Container, Row, Table } from 'react-bootstrap';
import Title from '../../../title';

function History() {
    const cookie = new Cookies()
    const user = cookie.get('email');
    const [histories, setHistories] = useState([]);

    useEffect(
        () => {
            getHistories().then(async res => {
                const filterHistories = [];
                res.data.forEach(element => {
                    if (element?.user === user) {
                        filterHistories.push(element)
                    }
                });

                setHistories(filterHistories)
            }).catch(error => console.log(error));
        },
        [])



    return (
        <Col lg={12} md={12} sm={12} xs={12}>
            <div className='historiesWrapper'>
                <Title title="Histories" />
                <div className='historiesContainer'>
                    <Table responsive="sm">
                        <thead className='historyTableHead'>
                            <tr>
                                <th><b>Date</b></th>
                                <th><b>Game</b></th>
                                <th><b>Team 1 Odds</b></th>
                                <th><b>Team 2 Odds</b></th>
                                <th><b>Bet Placed</b></th>
                                <th><b>Winings</b></th>
                                <th><b>Losses</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                histories?.map(item => (
                                    <>
                                        <tr key={item?.user}>
                                            <td align='left'><p>{moment(item?.date).format('L')}</p></td>
                                            <td align='left'><p>{item?.team1} vs {item?.team2}</p></td>
                                            <td align='left'><p>{item?.team1odds}</p></td>
                                            <td align='left'><p>{item?.team2odds}</p></td>
                                            <td align='left'><p>{item?.betPlaced + " " + item?.currency}</p></td>
                                            <td align='left'><p>{item?.winings + " " + item?.currency}</p></td>
                                            <td align='left'><p>{item?.losses + " " + item?.currency}</p></td>
                                        </tr>
                                    </>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </Col>
    )
}

export default History