import React from 'react'
import './styles.css'
import Cookies from 'universal-cookie';
import { useState } from 'react';
import { navLinks } from '../navigation/navLinks';
import Modale from '../modal';
import Title from '../title';

const SportsLinks = () => {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);


    return (
        <div className='sportsLinkWrapper'>
            <Title title="sports" />
            <div className='sportsLinkContainer'>
                {
                    navLinks?.map(link => (
                        <p className='sportsLink' onClick={() => setModalShow(true)}>{link.link}</p>
                    ))
                }
            </div>

            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                setShow={setModalShow}
                signin={cookie.get('token') ? false : true}
                message={cookie.get('token') ? true : false}
            />
        </div>
    )
}

export default SportsLinks