import { Button, Container, Row } from 'react-bootstrap';
import './style.css'
import moment from 'moment';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import Title from '../title';



export default function News({ news }) {

    return (
        <>
            <Container style={{ marginTop: 32 }}>
                <Title title="Stories" />
                <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={40}
                        loop={true}
                        pagination={false}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {
                            news.map((data, index) => (
                                <SwiperSlide>
                                    <div className='newsContainer'>
                                        <div className='newsImage'>
                                            <img src={data.enclosures[0].url} alt="news" />
                                        </div>
                                        <div className='newsBody'>
                                            <h4 className="newsBodyTitle">{data.title}</h4>
                                            <p className='newsDate' >Published: {" " + moment(data.published).format('L HH:MM')}</p>
                                            <Button className='newsReadMoreButton'>
                                                <a
                                                    href={data.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Read More
                                                </a>
                                            </Button>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                    {/* {
                        news?.map((data, index) => (
                            <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                                <div className='newsContainer'>
                                    <div className='newsImage'>
                                        <img src={data.enclosures[0].url} alt="news" width={200} height={150} />
                                    </div>
                                    <div className='newsBody'>
                                        <p className="newsBodyTitle">{data.title}</p>
                                        <div className='newsBodyDateButton'>
                                            <p className='newsDate' >{moment(data.published).format('L HH:MM')}</p>
                                            <Button className='newsReadMoreButton'>
                                                <a
                                                    href={data.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Read More
                                                </a>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                    } */}
                </Row>
            </Container>
        </>
    )
}
