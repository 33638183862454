import React from 'react'
import { Nav, Navbar, Container } from 'react-bootstrap'
import './style.css';
import DropDown from '../dropdown';
import Buttons from '../button';
import Cookies from 'universal-cookie';

export default function Navigation() {
    const cookie = new Cookies();
    return (
        <div className="navContainer">
            <Container >
                <Navbar expand="md" style={{ padding: 0, }}>
                    <Navbar.Brand href="/">
                        <div className="logo"></div>
                    </Navbar.Brand>
                    <Navbar.Toggle className="hamburgerButton" aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto" ></Nav>
                        {
                            cookie.get('token') ?
                                <>
                                    <Buttons text='History' kind="transparent2" history={true} />
                                    <Buttons text='Deposit' kind="transparent2" deposite={true} />
                                    <Buttons text='Withdraw' kind="transparent2" withdraw={true} />
                                    <Buttons text='Transactions' kind="transparent2" transaction={true} />
                                    <Buttons text='Profile' kind="transparent2" profile={true} />
                                    <DropDown />
                                </>
                                :
                                <>
                                    <div style={{ marginRight: 16 }}>
                                        <Buttons text='sign in' kind="transparent2" signin={true} />
                                    </div>
                                    <Buttons text='sign up' kind="transparent2" signup={true} />
                                </>

                        }
                    </Navbar.Collapse>
                </Navbar >
            </Container >
        </div>
    )
}
