import React, { useState } from 'react'
import './styles.css'
import Cookies from 'universal-cookie'
import { useFormik } from 'formik'
import { Button, Col, Container, Row, Form } from 'react-bootstrap'
import Title from '../../../title'


function Deposit() {
    const cookie = new Cookies()

    return (
        <Container>
            <Row xxl={12} xl={12} lg={12}>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                    <div className='depositeWrapper'>
                        <div className='depositeContainer'>
                            <Title title="deposit" />
                            <p className='depositeTitle'>Wallet Adress:</p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>

                                <Form.Control
                                    disabled
                                    className='textInput'
                                    value={cookie.get('wallet')}
                                    type="text"
                                    name="wallet"
                                />

                                <Button
                                    variant="primary"
                                    onClick={() => navigator.clipboard.writeText(cookie.get('wallet'))}
                                    className="copyButton">
                                    Copy Wallet
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Deposit