import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import usa from '../../assets/images/usa.webp'
import moment from 'moment';
import './style.css'
import Title from '../title';

export default function UserTable(props) {

    return (
        <>
            <Container fluid >
                <Row style={{ padding: 0 }}>
                    <Title title={props.title} />
                    <Row className='gameTableContainer' >
                        {
                            props.data?.map((data, index) => (
                                <Col xxl={3} xl={3} lg={3}>
                                    <div className="gameCard">
                                        <p>
                                            <span><img src={usa} width={25} height={15} style={{ marginRight: 4 }} alt="image1" />{data?.home_team}</span>
                                            <span className='homeTeam'>Home</span>
                                        </p>
                                        <p>
                                            <span><img src={usa} width={25} height={15} style={{ marginRight: 4 }} alt="image2" />{data?.away_team}</span>
                                            <span className='awayTeam'>Away</span>
                                        </p>
                                        <p className='gameTime'>
                                            Time:{" " + moment().format('L')}
                                        </p>
                                    </div>
                                    <div className='divider'></div>
                                </Col>
                            ))
                        }

                    </Row>
                </Row>
            </Container>
        </>
    )
}
