import { Row, Col } from 'react-bootstrap';
import './App.css';
import Navigation from './components/navigation'
import TableContainer from './components/table/tableContainer';
import Footer from './components/Footer';
import News from './components/news';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getResults } from '../src/api'
import SportsLinks from './components/sportsLink';
import USALeagues from './components/usaLeagues';
import Reviews from './components/Reviews';
import Slideshow from './components/slideshow';


function App() {
  const [news, setNews] = useState([]);
  const [games, setGames] = useState([]);

  useEffect(() => {
    axios.get('/api/news')
      .then(response => {
        setNews(response.data.items);
      })
      .catch(error => console.log(error));

    getResults().then(async response => {
      if (response) {
        setGames(response)
      }
    })
      .catch(error => console.log(error));
  }, []);


  return (
    <div style={{ overflowX: 'hidden' }}>
      <Navigation />

      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
          <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
            <USALeagues />
          </Row>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
          <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
            <Slideshow />
          </Row>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
          <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
            <News news={news} setNews={setNews} />
          </Row>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
          <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
            <SportsLinks />
          </Row>
        </Col>
        <Col lg={12} md={12} sm={12}>
          <Row>
            <TableContainer games={games} />
          </Row>
        </Col>
        <Col lg={12} md={12} sm={12}>
          <Row>
            <Reviews />
          </Row>
        </Col>
      </Row>
      <Row>
        <Footer />
      </Row>

    </div >
  );
}

export default App;
