import React from 'react'
import './styles.css'
import reviews from './data'
import { Col, Container, Row } from 'react-bootstrap'
import Title from '../title'

const Reviews = () => {
    return (
        <>
            <div className='reviewsWrapper'>
                <Row>
                    <Col xxl={7} xl={7} lg={7}>
                        <Title title="Reviews" />
                        <p className='reviewsSentence'>
                            Discover What Our Users Are Saying About Their Extraordinary Experience.
                        </p>
                    </Col>
                    <Col xxl={5} xl={5} lg={5}>
                        <div className='reviewsContainer'>
                            {
                                reviews.map((review) => (
                                    <div className='reviewContainer'>
                                        <div className='reviewImage'>
                                            <img src={review.avatar} width={100} height={100} alt={review.name} />
                                        </div>
                                        <div className='reviewBodyContainer'>
                                            <h4>{review.name}</h4>
                                            <p>{review.text}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Reviews